<template>
  <div class="page">
    <div class="page-header">
      <el-button type="primary" @click="exportItem" v-has="'export'">
        {{ $t('export') }}
      </el-button>
    </div>
    <div class="page-main">
      <el-form :inline="true" :model="tabelSearch">
        <div class="page-tools">
          <el-form-item v-has="'delete'">
            <el-button
              type="primary"
              icon="el-icon-plus iconfont icon-delete"
              @click="deleteItem()"
              :disabled="!tableSelection.length"
              >{{ $t('delete') }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="allRead()"
              >{{ $t('alarmList.markAllAsRead') }}
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <el-button type="text" class="btn-p0" @click="filter = !filter">
              {{ $t('filter') }}
              <i :class="filter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right keywords" v-has="'list'">
            <el-input
              v-model="tabelSearch.keywords"
              :placeholder="$t('alarmList.keywordsPla')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
            ></el-input>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <dist-select
              v-model="tabelSearch.level"
              :optionData="alarmLevel"
              :selectAll="{ label: $t('alarmList.allLevels'), value: null }"
              @change="getTabelData"
            ></dist-select>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <dist-select
              v-model="tabelSearch.type"
              :optionData="alarmType"
              :selectAll="{ label: $t('alarmList.allTypes'), value: null }"
              @change="getTabelData"
            ></dist-select>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <el-select v-model="tabelSearch.status" @change="getTabelData">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="page-tools" v-show="filter" v-has="'list'">
          <el-form-item class="page-tools-right">
            <!-- <el-date-picker
              v-model="datePicker"
              type="datetimerange"
              :start-placeholder="$t('startTime')"
              :end-placeholder="$t('endTime')"
              value-format="timestamp"
              :default-time="['00:00:00', '23:59:59']"
              @change="getTabelData"
              style="width: 100%"
            ></el-date-picker> -->
            <select-time
              :startTime="tabelSearch.beginTime"
              :endTime="tabelSearch.endTime"
              @change="changeTime"
            />
          </el-form-item>
        </div>
      </el-form>
      <table-pagination
        memory="alarmList"
        ref="refTable"
        :height="pageTabelHeight"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <!-- 状态栏 -->
        <!-- <template slot="header_statusLabel" slot-scope="scope">
          <div @click="test(scope.data.column.label)">{{scope.data.column.label}}</div>
        </template> -->
        <!-- status -->
        <template slot="statusLabel" slot-scope="scope">
          <el-dropdown @command="statusCommand">
            <el-button type="text" class="btn-p0">
              <i class="stateDot" :class="alarmStatusColor[scope.row.status]"></i>
              <span class="status">{{ alarmStatus[scope.row.status] }}</span>
              <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu class="status-menu" slot="dropdown">
              <template v-for="(value, label) in alarmStatus">
                <el-dropdown-item
                  :class="'status-' + value.toLowerCase()"
                  :command="{ id: scope.row.id, status: label }"
                  :key="label"
                  v-if="label != 'unread'"
                  >{{ value }}</el-dropdown-item
                >
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <!-- status -->
        <template slot="level" slot-scope="scope">
          <div :class="levelColor(scope.row.level)">{{ alarmLevel[scope.row.level] }}</div>
        </template>
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('alarmList.alarmDetails')"
            placement="top"
            :open-delay="1000"
          >
            <el-button type="text" class="btn-p0" @click="detailsItem(scope.row)">
              <i class="el-icon-warning-outline iconfont icon-info"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip effect="dark" :content="$t('dialogDiagnostics')" placement="top" :open-delay="1000">
            <el-button type="text" class="btn-p0" @click="strategyItem(scope.row)" :disabled="(scope.row.deviceModel == 'H2') || (scope.row.deviceModel == 'H2P')">
              <i class="el-icon-first-aid-kit iconfont icon-strategy"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
    <dailog-alarm-details
      v-if="alarmDetailSVisible"
      :visible.sync="alarmDetailSVisible"
      :req="alarmDetailSReq"
      @readChange="getTabelData"
    ></dailog-alarm-details>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  alarmSearch,
  alarmExport,
  alarmRemoveBatch,
  alarmMarkAsRead,
  alarmMark,
} from '@/api/alarmList'
import { TablePagination, DistSelect, SelectTime } from '@/components'
import { downloadFile } from '@/plugins/methods'
import DailogAlarmDetails from './dailog-alarm-details'

export default {
  name: 'AlarmList',
  components: {
    TablePagination,
    DistSelect,
    DailogAlarmDetails,
    SelectTime
  },
  data() {
    return {
      deviceId: this.$route.query.id,
      filter: false,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'alarmTime',
        sortOrder: 'desc',
        keywords: null,
        beginTime: null,
        endTime: null,
        level: null,
        type: null,
        status: 'all',
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('device.status'),
          prop: 'statusLabel',
          minWidth: 150,
          slot: true,
        },
        {
          label: this.$t('device.macAddress'),
          prop: 'macAddress',
          minWidth: 170,
          filter: 'toUpperCase',
          sortable: 'custom',
        },
        {
          label: this.$t('device.deviceName'),
          prop: 'deviceName',
          minWidth: 150,
        },
        {
          label: this.$t('device.model'),
          prop: 'deviceModel',
          minWidth: 100,
          sortable: 'custom',
        },
        {
          label: this.$t('device.site'),
          prop: 'siteName',
          minWidth: 100,
          sortable: 'custom',
        },
        {
          label: this.$t('device.ip'),
          prop: 'privateIp',
          minWidth: 140,
        },
        {
          label: this.$t('device.alarmLevel'),
          prop: 'level',
          minWidth: 150,
          slot: true,
          sortable: 'custom',
          filter: (val) => this.alarmLevel[val],
        },
        {
          label: this.$t('device.alarmType'),
          prop: 'type',
          minWidth: 220,
          filter: (val) => this.alarmType[val],
        },
        {
          label: this.$t('device.alarmTime'),
          prop: 'alarmTime',
          minWidth: 160,
          sortable: 'custom',
          filter: 'dateFormat',
        },
      ],
      tableData: [],
      tableSelection: [],
      // 告警详情
      alarmDetailSVisible: false,
      alarmDetailSReq: {},
    }
  },
  computed: {
    ...mapGetters('dist', ['alarmLevel', 'alarmType', 'alarmStatus', 'alarmStatusColor']),
    ...mapState('memory', ['sidebarSite']),
    ...mapState('account', ['loginInfo']),
    ...mapGetters('session', ['pageTabelHeight']),
    statusOptions() {
      let list = []
      for (let key in this.alarmStatus) {
        list.push({
          value: key,
          label: this.alarmStatus[key],
        })
      }
      list.unshift({
        value: 'all',
        label: this.$t('alarmList.allStatus'),
      })
      return list
    },
    tabelParams() {
      let timezone = this.loginInfo.timezone
      return {
        ...this.tabelSearch,
        timezone: timezone.slice(1, timezone.length - 1),
        site: this.sidebarSite,
      }
    },
    // datePicker: {
    //   get() {
    //     if (this.tabelSearch.beginTime && this.tabelSearch.endTime) {
    //       return [this.tabelSearch.beginTime, this.tabelSearch.endTime]
    //     } else {
    //       return ''
    //     }
    //   },
    //   set(val) {
    //     this.tabelSearch.beginTime = val ? val[0] : ''
    //     this.tabelSearch.endTime = val ? val[1] : ''
    //   },
    // },
    // 等级颜色
    levelColor() {
      return function(level) {
        switch (level) {
          case 1:
            return 'level-critical'
          case 2:
            return 'level-major'
          case 3:
            return 'level-minor'
        }
      }
    },
  },
  watch: {
    'tabelParams.site'() {
      this.getTabelData()
    },
  },
  created() {
    this.getTabelData()
  },
  methods: {
    changeTime(event) {
      this.tabelSearch.beginTime = event[0] || null
      this.tabelSearch.endTime = event[1] || null
      this.getTabelData()
    },
    // 获取数据
    getTabelData() {
      alarmSearch(this.tabelParams)
        .then(({ data }) => {
          this.tableData = data.result.rows[0].list.map((e) => {
            return {
              ...e,
              statusLabel: this.alarmStatus[e.status],
            }
          }) // 表格数据
          this.tabelTotal = data.result.rows[0].total // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'alarmTime'
      this.getTabelData()
    },
    // 多选
    selectionChange(val) {
      this.tableSelection = val
    },
    // 删
    deleteItem() {
      this.$confirm(this.$t('alarmList.deleteTip'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          const ids = this.tableSelection.map((e) => e.id)
          alarmRemoveBatch(ids)
            .then((res) => {
              this.$message.success(res.data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 导出
    exportItem() {
      alarmExport(this.tabelParams).then((res) => {
        downloadFile(res)
      })
    },
    // 详情
    detailsItem(row) {
      this.alarmDetailSVisible = true
      this.alarmDetailSReq = { row }
    },
    // 诊断
    strategyItem(row) {
      this.$router.push({ path: '/diagnostics', query: { mac: row.macAddress } })
    },
    // 标置为已读
    allRead() {
      alarmMarkAsRead()
        .then(({ data }) => {
          this.$message.success(data.message)
          this.getTabelData()
        })
        .catch(() => {})
    },
    // 状态切换
    statusCommand({ id, status }) {
      alarmMark({ id, status })
        .then(({ data }) => {
          this.$message.success(data.message)
          this.getTabelData()
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.level-critical {
  color: $theme-red;
}
.level-major {
  color: $theme-blue;
}
.level-minor {
  color: $--color-primary;
}
.el-dropdown-menu {
  @mixin status-color($color) {
    color: $color;
    &:hover {
      background-color: #f6f6f6;
      color: $color;
    }
    &:focus {
      background-color: #f6f6f6;
      color: $color;
    }
  }
  .status-resolved {
    @include status-color($--color-success);
  }
  .status-read {
    @include status-color(#55beff);
  }
  .status-ignore {
    @include status-color($--color-info);
  }
}
.status {
  padding: 0 5px;
  width: 55px;
  color: $--color-text-regular;
  text-align: left;
}
</style>
