<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="cell">
      <span class="label">{{ $t('mac') }}: </span>
      <span class="value">{{ req.row.macAddress }}</span>
    </div>
    <div class="cell">
      <span class="label">{{ $t('alarmTime') }}: </span>
      <span class="value">{{ req.row.alarmTime | dateFormat('YYYY/MM/DD HH:mm:ss') }}</span>
    </div>
    <!-- <div v-if="[1, 5, 7].includes(req.row.type)">
      <span style="font-weight: bold;">Failed Account: </span>暂无数据
    </div> -->
    <div class="cell" v-if="[8].includes(req.row.type)">
      <span class="label">{{ $t('updateType') }}: </span>
      <span class="value">{{ alarmType[req.row.type] }}</span>
    </div>
    <div class="cell">
      <span class="label">{{ $t('reason') }}: </span>
      <span class="value">{{ req.row.reason }}</span>
    </div>
    <span class="footer-btns" slot="footer">
      <el-button class="footer-btn" type="primary" plain @click="handleClose">{{ $t('close') }}</el-button>
      <el-button :disabled="(req.row.deviceModel == 'H2') || (req.row.deviceModel == 'H2P')" class="footer-btn" @click="submit" type="primary">{{ $t('dialogDiagnostics') }} </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { alarmMark } from '@/api/alarmList'

export default {
  name: 'dailog-alarm-details',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('alarmList.alarmDetails')
      },
    },
    req: Object,
  },
  computed: {
    ...mapGetters('dist', ['alarmType']),
  },
  data() {
    return {
      form: {
        test: '',
      },
    }
  },
  created() {
    console.log('req.row.deviceModel: ', this.req.row.deviceModel)
    if (this.req.row.status == 'unread') this.readChange()
  },
  methods: {
    // 已读
    readChange() {
      alarmMark({ id: this.req.row.id, status: 'read' }, true)
        .then(() => {})
        .catch(() => {})
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('readChange')
    },
    // 提交
    submit() {
      this.$router.push({ path: '/diagnostics', query: { id: this.req.row.deviceId,mac: this.req.row.macAddress } })
    },
  },
}
</script>

<style lang="scss" scoped>
  .cell {
    display: flex;
    margin-top: 40px;
    padding: 0 112px;
    font-size: 20px;
    &:first-child {
      margin-top: 0;
    }
    .label {
      min-width: 170px;
      margin-right: 40px;
      text-align: right;
    }
    .value {
      color: #999;
      word-break: break-word;
    }
  }
</style>