import axios from '@/plugins/axios.js'

// 导出
export const alarmExport = (params) => axios.post('/alarm/export', params, { responseType: 'blob' })

// 单条标记
export const alarmMark = (params, noLoading) =>
  axios.post('/alarm/mark', params, {
    data: {
      noLoading,
    },
  })

// 标记为已读
export const alarmMarkAsRead = (params) => axios.get('/alarm/markAsRead', { params })

// 删除
export const alarmRemoveBatch = (data) => axios.delete('/alarm/removeBatch', { data })

// 搜索
export const alarmSearch = (params) => axios.post('/alarm/search', params)
